import React from 'react';
// eslint-disable-next-line max-len
import { StyleSheet, Text, View, TouchableWithoutFeedback, Dimensions, Animated } from 'react-native';
import { Audio } from 'expo';
import Bpmdrop from './Bpmdrop.js';

const ScreenHeight = Dimensions.get('window').height;
const styles = StyleSheet.create({
  bubble: {
    position: 'absolute',
    left: 100,
    top: 100,
    backgroundColor: 'lightblue',
    opacity: 0.4,
  },
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    height: ScreenHeight,
  },
  placeholder: {
    color: '#A4A4A4',
    fontSize: 32,
    fontWeight: '300',
  },
  resultValue: {
    fontSize: 112,
    fontWeight: '200',
  },
  resultUnit: {
    paddingLeft: 80,
    fontSize: 32,
    fontWeight: '300',
  },
});
export default class App extends React.Component {

  constructor() {
    super();

    this.state = {
      lastTap: null,
      tapDurations: [],
      fadeAnim: new Animated.Value(100),
      drops: [],
      rippleTimer: null,
      bpm: null,
      interval: null,
    };
  }

  restart() {
    console.log('Restart called');
    this.setState({
      lastTap: null,
      tapDurations: [],
      bpm: null,
      display: 'none',
    });
    clearInterval(this.state.rippleTimer);
  }

  deleteDrop({ drop }) {
    // console.log(this.state.drops.length, 'drops', this.state.drops.map(e => e.key))
    this.state.drops = this.state.drops.slice(1); // .filter(e => e !== drop);
    // console.log(this.state.drops.length, 'drops')
  }

  /* TODO: implement Metronome click */
  playSound = async () => {
    console.log("playing sound");
    const soundObject = new Audio.Sound();
    try {
      await soundObject.loadAsync('/assets/hi_click.wav');
      this.audioPlayer1 = soundObject;
      this.audioPlayer1.playAsync();
      this.audioPlayer1.setPositionAsync(0);
      this.audioPlayer1.setRateAsync(3, false);
    } catch (error) {
      console.log(error);
    }
  }

  createDrops(pos) {
    const elem = React.createElement(Bpmdrop, {
      key: Math.random(),
      X: pos.X,
      Y: pos.Y,
      onDropEnd: this.deleteDrop.bind(this),
    });
    this.setState({
      drops: this.state.drops.concat(elem),
    });
  }

  // createDrops2(pos) {
  //   const elem = React.createElement(Bpmdrop, {
  //     key: Math.random(),
  //     X: pos.X,
  //     Y: pos.Y,
  //     onDropEnd: this.deleteDrop.bind(this),
  //   });
  //   this.state.drops.push(elem);
  //   console.log(pos);
  // }

  startRippleTimer(pos, interval) {
    console.log("rippletimer started!", interval);
    if (this.state.rippleTimer) {
      clearInterval(this.state.rippleTimer);
    }
    // this.createDrops(pos);

      const rippleTimer = setInterval(() => this.createDrops(pos), (interval * 1000));
      this.setState({ rippleTimer });

  }

  _onPress(evt) {
    const pos = { X: evt.nativeEvent.pageX, Y: evt.nativeEvent.pageY };
    this.createDrops(pos);
    // first tap duration
    let duration = 1;
    const now = new Date();

    // IF: second TAP
    if (this.state.lastTap) {
      duration = (now.getTime() - this.state.lastTap.getTime()) / 1000;
      // Restart RippleTimer if
      if (duration > 2) {
        this.restart();
        return;
      }
      this.state.tapDurations.push(duration);
    }

    // set lastTap to current tap
    this.setState({ lastTap: now });

    // IF: third tap
    if (this.state.tapDurations.length >= 2) {
      let sum = 0;
      for (let i = 0; i < this.state.tapDurations.length; i++) {
        sum += this.state.tapDurations[i];
      }
      const interval = sum / this.state.tapDurations.length;
      const bpm = Math.round(60 / (interval));
      this.setState({ bpm });

      if (this.state.tapDurations.length >= 20) {
        this.state.tapDurations.splice(0, 1);
      }
      // start ripple timer
      this.startRippleTimer(pos, interval);
    }
  }
  render() {
    const visible = check => ({
      display: check ? 'flex' : 'none',
    });

    const { drops, bpm } = this.state;

    return (
      <TouchableWithoutFeedback onPressIn={evt => this._onPress(evt)}>
        <View style={styles.container}>
          <Text style={{ ...styles.placeholder, ...visible(!bpm) }}>tap to bpm</Text>
          <Text style={{ ...visible(bpm) }}>
            <Text style={styles.resultValue}>{this.state.bpm}</Text>
            <Text style={styles.resultUnit}> bpm</Text>
          </Text>
          {drops}
        </View>
      </TouchableWithoutFeedback>

    );
  }

}
