import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Animated, Easing } from 'react-native';

const styles = StyleSheet.create({
  bubble: {
    position: 'absolute',
    backgroundColor: 'rgb(0,122,255)',
  },
});

export default class Bpmdrops extends React.Component {

  static get propTypes() {
    return {
      X: PropTypes.number,
      Y: PropTypes.number,
      onDropEnd: PropTypes.func,
    };
  }

  constructor(props) {
    super();

    this.state = {
      growAnim: new Animated.Value(1),
      fadeAnim: new Animated.Value(1),
      position: {
        X: props.X,
        Y: props.Y,
      },
      render: true,
    };
    this.extend(Easing.out(Easing.quad));
  }

  extend(easing) {
    Animated.parallel([
      Animated.timing(
        this.state.fadeAnim,
        {
          toValue: 0,
          duration: 1000,
          easing,
        }
      ),
      Animated.timing(
        this.state.growAnim,
        {
          toValue: 8,
          duration: 1000,
          easing,
        }
      )]).start(() => this.props.onDropEnd({ drop: this }));
  }


  render() {
    const { display, position, growAnim, fadeAnim, render } = this.state;
    if (render === false) return null;
    return (<Animated.View style={[styles.bubble,
      {
        display,
        top: (parseFloat(position.Y) - 12),
        left: parseFloat(position.X - 12),
        width: 24,
        height: 24,
        transform: [{ scale: growAnim }],
        borderRadius: 12,
        opacity: fadeAnim,
      },
    ]}></Animated.View>);
  }

}
